import { auth } from '@osrdata/app_core'
import 'App.scss'
import terms from 'common/terms'
import AccessDenied from 'components/AccessDenied/AccessDenied'
import Loader from 'components/Loader'
import TabList from 'components/TabList/TabList'
import TopBar from 'components/TopBar/TopBar'
import history from 'customHistory'
import {
  ReactElement, Suspense, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Router, Switch,
} from 'react-router-dom'
import { RootState } from 'Store'
import navRoutes from 'utils/navRoutes'

const APP_NAME = 'anticipe'
const ACCESS_PERM = 'anticipe::ACCESS'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged, appPermissions, isLoading } = useSelector((state: RootState) => state.user)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
    }
  }, [isLogged, appPermissions])

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <TopBar
            appName={APP_NAME}
            innerComponent={<TabList tabs={navRoutes} />}
            hasAccess={!isLoading && !accessDenied}
          />
          {isLoading && <div id="app"><Loader message={terms.Common.loading} /></div>}
          {!isLoading && (accessDenied ? <AccessDenied /> : (
            <div id="app">
              <Switch>
                {navRoutes.map(route => (
                  <Route exact component={route.component} path={route.path} key={route.path} />
                ))}
                <Redirect to={terms.Navigation.geographic.path} />
              </Switch>
            </div>
          ))}
        </Router>
      </Suspense>
    </>
  )
}

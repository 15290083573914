import { MAP_LAYER_SOURCE } from 'components/Layers/const'

/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
    allCheck: 'Tout sélectionner',
    allUncheck: 'Tout désélectionner',
    create: 'Créer',
    pendingQuery: 'Requête en cours...',
    Error: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application',
      appSubtitle: 'Veuillez contacter votre administrateur',
      exitButton: 'Retour au portail des applications',
      server: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 500)',
      unknown: 'Une erreur est survenue.',
      notFound: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 404)',
      badRequest: 'Une erreur est survenue. Vérifiez les informations que vous avez entrées et réessayez.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
    },
    name: 'Nom',
    description: 'Description',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    add: 'Ajouter',
  },
  Navigation: {
    linear: {
      title: 'Vue linéarisée',
      path: '/vue-lineaire',
    },
    geographic: {
      title: 'Vue géographique',
      path: '/vue-geographique',
    },
  },
  Map: {
    Toolbar: {
      lightTheme: 'Fond de carte sobre',
      fullTheme: 'Fond de carte riche',
      resetMapView: 'Réinitialiser la vue',
      sncfRegion: 'SNCF',
      adminRegion: 'Administratives',
      borders: 'Frontières',
    },
    Popup: {
      termsTitle: {
        [MAP_LAYER_SOURCE.station]: 'Gare ',
        [MAP_LAYER_SOURCE.localisateur]: 'Localisateur ',
        [MAP_LAYER_SOURCE.acces]: 'Accès ',
        [MAP_LAYER_SOURCE.jdz]: 'Joint de zone ',
        [MAP_LAYER_SOURCE.centraleSousStation]: 'Centrale sous station ',
        [MAP_LAYER_SOURCE.sousStation]: 'Sous station ',
        [MAP_LAYER_SOURCE.feeder]: 'Feeder ',
        [MAP_LAYER_SOURCE.sector]: 'Secteur ',
        [MAP_LAYER_SOURCE.subSector]: 'Sous secteur ',
        [MAP_LAYER_SOURCE.adv]: 'Appareil de voie ',
        [MAP_LAYER_SOURCE.cdv]: 'Circuit de voie ',
        [MAP_LAYER_SOURCE.signal]: 'Signal ',
      },
      Circulation: {
        type: 'Type',
        number: 'N°',
        origin: 'ORIGINE',
        passage: 'PASSAGE',
        departure: 'DÉPART',
        arrival: 'ARRIVÉE',
        terminus: 'TERMINUS',
        noTimeline: 'Aucune information de circulation',
        minutes: 'min',
      },
      libelle: 'Libellé',
      regionSncfId: 'ID région SNCF',
      idReseau: 'ID réseau',
      id: 'ID',
      ci: 'Code CI',
      ch: 'Code CH',
      ciLibelle: 'Libellé CI',
      chLibelle: 'Libellé CH',
      gaiaId: 'ID Gaïa',
      eic: 'EIC',
      numWindow: 'N° fenêtre',
      numLocator: 'N° localisateur',
      moduleId: 'Module suivi id réseau',
      line: 'Ligne',
      track: 'Voie',
      pk: 'PK',
      objectType: 'Type d\'objet :',
    },
    Layer: {
      Category: {
        socle: 'Socle',
        circulationState: 'État des circulations',
        environementRail: 'Environnement ferroviaire',
        securityInstallations: 'Installations de sécurité',
        electricPowerSupply: 'Alimentation électrique',
      },
      station: 'Bâtiment voyageurs',
      locator: 'Localisateur',
      track: 'Plan de voies',
      trackLinear: 'Plan de voies',
      pk: 'Point kilométrique',
      pr: 'Point remarquable',
      tunnel: 'Tunnel',
      onTime: 'A l\'heure',
      late: 'Léger retard',
      veryLate: 'Retard important',
      acces: 'Point d\'accès aux voies',
      railwayCrossing: 'Passage à niveau',
      jdzLayer: 'Joint de zone',
      centraleSousStation: 'Centrale sous station',
      sousStation: 'Sous station',
      feeder: 'Feeder',
      sector: 'Secteur',
      subSector: 'Sous secteur',
      adv: 'Appareil de voie',
      cdv: 'Circuit de voie',
      signal: 'Signal',
      signalOther: 'Signal autre',
    },
  },
}

export default terms

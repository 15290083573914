import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BorderType } from 'components/Layers/types'
import { DEFAULT_VIEWPORT, LINEAR_VIEWPORT, MapTheme } from 'components/Map/const'
import { ViewportProps } from 'react-map-gl'
import { LAYERS_MENU } from 'utils/const'
import { MapboxGeoJSONFeature } from 'mapbox-gl'
import { Draft } from 'immer'
import { MapState } from './types'

const initialState: MapState = {
  viewportLinear: LINEAR_VIEWPORT,
  viewportGeo: DEFAULT_VIEWPORT,
  currentOsmTheme: MapTheme.full,
  viewMode: 'geo',
  currentBorderType: BorderType.admin,
  layersList: LAYERS_MENU,
  hoveredFeature: null,
}

export const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {

    saveGeoViewport: (state, action: PayloadAction<ViewportProps>) => {
      state.viewportGeo = { ...action.payload }
    },

    saveLinearViewport: (state, action: PayloadAction<ViewportProps>) => {
      state.viewportLinear = { ...action.payload }
    },

    updateTheme: (state, action: PayloadAction<MapTheme>) => {
      state.currentOsmTheme = action.payload
    },

    updateBorders: (state, action: PayloadAction<BorderType |null>) => {
      state.currentBorderType = action.payload
    },

    setAllDisplayedLayersByCategory: (state, action: PayloadAction<{ categoryName: string, checked: boolean }>) => {
      state.layersList = state.layersList.map(layerItem => {
        if (layerItem.category === action.payload.categoryName) {
          return {
            ...layerItem,
            layers: layerItem.layers.map(layer => ({
              ...layer,
              visibility: action.payload.checked,
            })),
          }
        }
        return layerItem
      })
    },

    setLayersDisplayed: (state, action: PayloadAction<{category: string, layer: string, checked: boolean}>) => {
      state.layersList = state.layersList.map(layerItem => {
        if (layerItem.category === action.payload.category) {
          return {
            ...layerItem,
            layers: layerItem.layers.map(layer => {
              if (layer.layer === action.payload.layer) {
                return {
                  ...layer,
                  visibility: action.payload.checked,
                }
              }
              return layer
            }),
          }
        }
        return layerItem
      })
    },

    setHoveredFeature: (state, action: PayloadAction<Draft<MapboxGeoJSONFeature> | null>) => {
      state.hoveredFeature = action.payload
    },
  },
})

export const {
  saveGeoViewport,
  saveLinearViewport,
  updateTheme,
  updateBorders,
  setLayersDisplayed,
  setAllDisplayedLayersByCategory,
  setHoveredFeature,
} = mapSlice.actions

export default mapSlice.reducer

import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { RootState } from 'Store'
import { NavElement } from 'utils/navRoutes'
import './TabElement.scss'

type Props = {
  element: NavElement;
}
export default function TabElement({ element }: Props): ReactElement {
  const {
    icon,
    title,
    path,
    role,
  } = element
  const location = useLocation()
  const isSelected = location.pathname.includes(path)
  const { appPermissions } = useSelector((state: RootState) => state.user)
  const isAllowed = role.length === 0
    || ((appPermissions as string[]).some(permission => role.includes(permission)))
  return (
    <>
      {isAllowed
        && (
          <Link to={path} key={path}>
            <div
              className={`tab-element d-flex justify-content-center align-items-center
            h-100 px-3 ${isSelected ? 'selected' : ''}`}
            >
              <div>
                <img src={icon} alt="tab icon" />
              </div>
              {
                (title && isSelected) && (
                  <div className="title ml-2">
                    {title}
                  </div>
                )
              }
            </div>
          </Link>
        )}
    </>
  )
}

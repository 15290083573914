import terms from 'common/terms'
import { HideableLayers } from 'components/Layers/types'
import { LAYER_NAMES } from 'components/Layers/const'
import { MapViewMode } from 'components/Map/const'

const LAYERS_MENU: HideableLayers[] = [
  {
    category: terms.Map.Layer.Category.socle,
    layers: [
      {
        name: terms.Map.Layer.station,
        layer: LAYER_NAMES.station,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.track,
        layer: LAYER_NAMES.track,
        visibility: true,
        mode: [MapViewMode.geo],
      },
      {
        name: terms.Map.Layer.trackLinear,
        layer: LAYER_NAMES.trackSch,
        visibility: true,
        mode: [MapViewMode.linear],
      },
    ],
  },
  {
    category: terms.Map.Layer.Category.environementRail,
    layers: [
      {
        name: terms.Map.Layer.locator,
        layer: LAYER_NAMES.localisateur,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.railwayCrossing,
        layer: LAYER_NAMES.pn,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.pk,
        layer: LAYER_NAMES.pk,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.pr,
        layer: LAYER_NAMES.pr,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.acces,
        layer: LAYER_NAMES.acces,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      // {
      //   name: terms.Map.Layer.tunnel,
      //   layer: LAYER_NAMES.tunnelLayer,
      //   visibility: true,
      //   mode: Object.values(MapViewMode),
      // },

    ],
  },
  {
    category: terms.Map.Layer.Category.securityInstallations,
    layers: [
      {
        name: terms.Map.Layer.jdzLayer,
        layer: LAYER_NAMES.jdz,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.adv,
        layer: LAYER_NAMES.adv,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.cdv,
        layer: LAYER_NAMES.cdv,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.signal,
        layer: LAYER_NAMES.signal,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.signalOther,
        layer: LAYER_NAMES.signalOther,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
    ],
  },
  {
    category: terms.Map.Layer.Category.electricPowerSupply,
    layers: [
      {
        name: terms.Map.Layer.feeder,
        layer: LAYER_NAMES.feeder,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.sousStation,
        layer: LAYER_NAMES.sousStation,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.centraleSousStation,
        layer: LAYER_NAMES.centraleSousStation,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      /* {
        name: terms.Map.Layer.sector,
        layer: LAYER_NAMES.sector,
        visibility: true,
        mode: Object.values(MapViewMode),
      },
      {
        name: terms.Map.Layer.subSector,
        layer: LAYER_NAMES.subSector,
        visibility: true,
        mode: Object.values(MapViewMode),
      }, */
    ],
  },
]

export {
  // eslint-disable-next-line import/prefer-default-export
  LAYERS_MENU,
}

import config from 'config/config'
import { FeatureCollection } from 'geojson'

const MAP_LAYER_SOURCE = {
  track: 'pm_sig_raw_geo_track',
  pk: 'cassini_reperekilometrique',
  tunnel: 'cassini_tunnel',
  locator: 'cassini_v2_localisateurs',
  signal: 'cassini_v2_gaia_signal',
  station: 'cassini_v2_gaia_gare',
  poste: 'cassini_v2_gaia_poste',
  jdz: 'cassini_v2_gaia_joint_de_zone',
  acces: 'cassini_v2_gaia_acces',
  sousStation: 'cassini_v2_gaia_sous_station',
  centraleSousStation: 'cassini_v2_gaia_central_sous_station',
  pr: 'cassini_v2_gaia_point_remarquable',
  localisateur: 'cassini_v2_gaia_localisateur',
  dbc: 'cassini_v2_gaia_detecteur_boite_chaude',
  trackVoieSch: 'cassini_v2_tmp_rer_b',
  pn: 'cassini_v2_gaia_passage_a_niveau',
  feeder: 'cassini_v2_gaia_feeder',
  sector: 'cassini_v2_gaia_secteur',
  subSector: 'cassini_v2_gaia_sous_secteur',
  adv: 'cassini_v2_gaia_appareil_de_voie',
  cdv: 'cassini_v2_gaia_circuit_de_voie',
}

const LAYER_NAMES = {
  trackSch: 'track-sch',
  track: 'track',
  pk: 'Repère-kilométrique',
  regionLayer: 'Région',
  tunnelLayer: 'Tunnel',
  signal: 'Signal',
  signalOther: 'Signal-autre',
  station: 'Gare',
  poste: 'Poste',
  jdz: 'Joint-de-zone',
  acces: 'Acces',
  sousStation: 'Sous-station',
  centraleSousStation: 'Centrale-sous-station',
  pr: 'Point-remarquable',
  localisateur: 'Localisateur',
  dbc: 'Détecteur-boite-chaude',
  pn: 'Passage-à-niveau',
  feeder: 'Feeder',
  sector: 'Secteur',
  subSector: 'Sous-secteur',
  adv: 'Appareil-de-voie',
  cdv: 'Circuit-de-voie',
}

const FEATURES_LAYERS = [
  LAYER_NAMES.signal,
  LAYER_NAMES.station,
  LAYER_NAMES.poste,
  LAYER_NAMES.jdz,
  LAYER_NAMES.acces,
  LAYER_NAMES.sousStation,
  LAYER_NAMES.centraleSousStation,
  LAYER_NAMES.pr,
  LAYER_NAMES.dbc,
  LAYER_NAMES.pn,
  LAYER_NAMES.feeder,
  LAYER_NAMES.sector,
  LAYER_NAMES.subSector,
  LAYER_NAMES.adv,
  LAYER_NAMES.cdv,
  LAYER_NAMES.signalOther,
]

const LAYER_URL = `${config.proxy}/chartis/v2/layer`

const EMPTY_FEATURE_COLLECTION: FeatureCollection = {
  type: 'FeatureCollection', features: [],
}

export {
  MAP_LAYER_SOURCE,
  LAYER_URL,
  LAYER_NAMES,
  EMPTY_FEATURE_COLLECTION,
  FEATURES_LAYERS,
}

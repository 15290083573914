import { lazy, LazyExoticComponent, ReactElement } from 'react'
import terms from 'common/terms'
import map from '../assets/icons/Vector.svg'
import linear from '../assets/icons/linear.svg'

const GeographicMap = lazy(() => import('../views/GeographicMap/GeographicMap'))
const LinearMap = lazy(() => import('../views/LinearMap/LinearMap'))

const appSlug = 'anticipe'

export type NavElement = {
  icon: string;
  title?: string;
  path: string;
  component: LazyExoticComponent<() => ReactElement>;
  role: Array<string>;
}

const navRoutes: NavElement[] = [
  {
    icon: map,
    title: terms.Navigation.geographic.title,
    path: terms.Navigation.geographic.path,
    component: GeographicMap,
    role: [`${appSlug}::ACCESS`],
  },
  {
    icon: linear,
    title: terms.Navigation.linear.title,
    path: terms.Navigation.linear.path,
    component: LinearMap,
    role: [`${appSlug}::ACCESS`],
  },
]

export default navRoutes

import { ReactElement } from 'react'
import { NavElement } from 'utils/navRoutes'
import TabElement from './TabElement'

type Props = {
  tabs: NavElement[]
}

const style = {
  flex: 1,
}

export default function TabList({ tabs }: Props): ReactElement {
  return (
    <nav className="d-flex align-item-center h-100 ml-3">
      <ul className="d-flex align-item-center h-100 ml-3">
        <li className="d-flex align-item-center h-100 ml-3" style={style}>
          {tabs.map(el => (
            <TabElement key={el.title} element={el} />
          ))}
        </li>
      </ul>
    </nav>
  )
}

import { LAYER_NAMES } from 'components/Layers/const'
import { ViewportProps } from 'react-map-gl'

enum MapTheme {
  light = 'light',
  full = 'full',
}

enum MapViewMode {
  geo = 'geo',
  linear = 'linear'
}

export type ViewMode = {
  viewMode: MapViewMode
}

const DEFAULT_VIEWPORT: ViewportProps = {
  latitude: 46.8025,
  longitude: 2.7643,
  zoom: 5.4389429465554,
  bearing: 0,
  pitch: 0,
}

const LINEAR_VIEWPORT: ViewportProps = {
  altitude: 1.5,
  bearing: 0,
  height: 755.265625,
  latitude: 48.60471106783999,
  longitude: 3.6390291712315785,
  maxPitch: 60,
  zoom: 12.89,
}

const INTERACTIVE_LAYERS = [
  LAYER_NAMES.pr,
  LAYER_NAMES.localisateur,
  `${LAYER_NAMES.localisateur}-name`,
  LAYER_NAMES.station,
  `${LAYER_NAMES.station}-name`,
  LAYER_NAMES.pn,
  LAYER_NAMES.acces,
  LAYER_NAMES.jdz,
  LAYER_NAMES.centraleSousStation,
  LAYER_NAMES.sousStation,
  LAYER_NAMES.feeder,
  LAYER_NAMES.sector,
  LAYER_NAMES.subSector,
  LAYER_NAMES.adv,
  `${LAYER_NAMES.adv}-name`,
  LAYER_NAMES.cdv,
  LAYER_NAMES.signal,
  `${LAYER_NAMES.signal}-name`,
  LAYER_NAMES.signalOther,
  `${LAYER_NAMES.signalOther}-name`,
]

export {
  MapTheme, DEFAULT_VIEWPORT, LINEAR_VIEWPORT, INTERACTIVE_LAYERS, MapViewMode,
}

import { MapViewMode } from 'components/Map/const'
import { LayerProps } from 'react-map-gl'

export type LayerProperties = {[layerName: string]: LayerProps}

export type ViewType =
'geo'
| 'line_geo'
| 'track_geo'
| 'full'
| 'full_rgi_track_geo'
| 'full_rgi_line_geo'
| 'full_rgi_track_sch'
| 'track_sch'
| 'full_tmp_rer_b'
| 'full_tmp_rer_b_centroid'
| 'full_rgi_track_geo_centroid'

export type FeatureProperties = {
  'fenetre_suivi_id': string;
  'num_localisateur': string;
  'id_reseau': string;
  'lrs_ligne': string;
  'lrs_voie': string;
  'lrs_pk': string;
  'code_ci': string;
  'code_ch': string;
  'libelle_court_ch': string;
  'libelle_long_ch': string;
  'id': string;
  'eic': string;
  'libelle': string;
  'region_sncf_id': string;
  'position': string;
}

export enum BorderType {
 eic = 'eic',
 admin = 'admin'
}

type Layer = {
  name: string;
  layer: string;
  visibility: boolean;
  mode: MapViewMode[];
}
export type HideableLayers = {
  category: string;
  layers: Layer[];
}

export type VisibleLayer = {
  visible: boolean
}

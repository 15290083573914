import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ResponseError } from './types'

export enum FeedbackType {
  validation = 'validation'
}

export interface FeedbackState {
  feedback?: ResponseError;
  message: string;
  messageReady?: boolean;

}

const initialState: FeedbackState = {
  feedback: undefined,
  message: '',
  messageReady: false,
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.feedback = action.payload
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
    setMessageReady: (state, action: PayloadAction<boolean>) => {
      state.messageReady = action.payload
    },
    snackbarClose: state => {
      state.feedback = undefined
      state.message = ''
      state.messageReady = false
    },
  },
})

export const {
  setError, setMessage, setMessageReady, snackbarClose,
} = feedbackSlice.actions

export default feedbackSlice.reducer
